<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-6 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">广州国企消费帮扶<br/>数据统计中心</h1>
              <p class="text-lead text-white ls-2">登录</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5" v-loading="loading">
              <div class="text-center text-muted mb-4">
                <small class="ls-2">请使用账号密码登录</small>
              </div>
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <base-input alternative
                              class="mb-3"
                              name="Username"
                              :rules="{required: true}"
                              prepend-icon="ni ni-single-02"
                              placeholder="账号名称"
                              v-model="model.username">
                  </base-input>

                  <base-input alternative
                              class="mb-3"
                              name="Password"
                              :rules="{required: true, min: 6}"
                              prepend-icon="ni ni-key-25"
                              type="password"
                              placeholder="登录密码"
                              v-model="model.password">
                  </base-input>

                  <base-checkbox v-model="model.rememberMe">记住我.</base-checkbox>
                  <div class="text-center">
                    <base-button type="primary" native-type="submit" class="my-4 ls-1 btn-block">登录</base-button>
                  </div>
                  <div class="text-center">
                    <router-link to="/register">注册</router-link>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
          <div class="row mt-3">
            <!--
            <div class="col-12  text-right">
              <router-link to="/dashboard" class="text-light"><small>忘记密码?</small></router-link>
            </div
            <div class="col-6 text-right" v-if="false">
              <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  /* eslint-disable */
  export default {
    data() {
      return {
        loading: false,
        model: {
          username: '',
          password: '',
          rememberMe: false
        }
      };
    },
    computed: {
      loginedUser() {
        return this.$store.state.user.user
      }
    },
    methods: {
      onSubmit() {
        if(!this.model.username || !this.model.password) return;
          this.loading = true;
          this.axios.post('login', this.model).then((res) => {
              if(res.data.errcode == 0) {
                  this.$store.commit('user/SetUser', res.data.user);
                  this.$store.commit("profiles/SetProfiles", res.data.profiles);
                  this.$store.commit("prefecture/SetPrefecture", res.data.prefecture);
                  this.$store.commit("org/SetOrg", res.data.org);
                  this.$notifyOK('登录成功', '欢迎您, ' + res.data.user.username)
                  if (res.data.org && res.data.org.status != 1) {
                    setTimeout(() => this.$router.push('/accountstatus'), 800)
                  } else {
                    setTimeout(() => this.$router.push('/dashboard'), 800)
                  }
              } else {
                  this.$notifyErr('登录失败', res.data.errmsg)
              }
              this.loading = false;
          }).catch((err) => {
              if(err.response && err.response.status == 401) this.$notifyErr('无效账号', '请检查账号名称和登录密码.')
              else if(err.response && err.response.status!=500) this.$notifyErr('登录失败', err.response.data.error.message);
              else this.$notifyErr('出问题了', err.message);
              console.log(err);
              this.loading = false;
          });
          return false;
      },
      logout() {
        this.axios
          .get("logout")
          .then((res) => {
            if (res.data.errcode == 0) {
              this.$store.commit("user/SetUser", null);
              this.$store.commit("profiles/SetProfiles", null);
              this.$store.commit("prefecture/SetPrefecture", null);
              this.$store.commit("org/SetOrg", null);
              this.$router.push("/login");
            } else {
              this.$notifyErr("登出失败", "登出操作当前不可用.");
            }
          })
          .catch((err) => {
            this.$notifyErr("登出失败", err.message);
            console.log(err);
          });
      },
      isLogined() {
        if(this.loginedUser) {
          const swal = require("sweetalert2");
          swal.fire({
            title: "您已经登录了",
            showCancelButton: true,
            confirmButtonClass: "btn btn-danger btn-fill",
            cancelButtonClass: "btn btn-default btn-fill",
            buttonsStyling: false,
            confirmButtonText: '登出',
            cancelButtonText: '返回'
          }).then((result) => {
            if (result.value) {
              this.logout()
            } else if (result.dismiss === swal.DismissReason.cancel) {
              this.$router.back();
            }
          })
        }
      }
    },
    beforeRouteEnter (to, from, next) {
      console.log(`from ${from.name}`)
      if(!from.name) {
        // first time open site on browser
        //const axios = require('axios');
        if (to.path != '/login') {
          axios
            .get("getsession")
            .then((res) => {
              if (res.data.errcode == 0) {
                next(vm => {
                  vm.$store.commit("user/SetUser", res.data.user)
                  vm.$store.commit("profiles/SetProfiles", res.data.profiles);
                  vm.$store.commit("prefecture/SetPrefecture", res.data.prefecture);
                  vm.$store.commit("org/SetOrg", res.data.org);
                  if (res.data.org && res.data.org.status != 1) {
                    setTimeout(() => vm.$router.push('/accountstatus'), 800)
                  } else {
                    vm.$router.push("/dashboard")
                  }
                })
              } else {
                next()
              }
            })
            .catch((err) => {
              if (err.response && err.response.status == 401)
                next()
              else {
                console.error(err)
                next()
              }
            });
        } else {
          next(vm => {
            vm.$store.commit("user/SetUser", null);
            vm.$store.commit("profiles/SetProfiles", null);
            vm.$store.commit("prefecture/SetPrefecture", null);
            vm.$store.commit("org/SetOrg", null);
          })
        }
      }
      else {
          next(vm => vm.isLogined())
      }
    }
  };
</script>
