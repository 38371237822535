<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7">
      <div class="container">
        <div class="header-body text-center mb-5">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">广州国企消费帮扶<br/>数据统计中心</h1>
              <p class="text-lead text-white ls-2">账号注册</p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8">
      <!-- Table --> 
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-8">
          <div class="card bg-secondary border-0">
            <div class="card-body px-lg-5">
              <div class="text-center text-muted mb-4">
                <small class="ls-2">请填写账号资料</small>
              </div>
              <el-form ref="regform" :model="model" label-width="auto" label-position="top" v-loading="loading">
                <div class="row">
                  <div class="col-12">
                    <el-form-item prop="username" :rules="[{ validator: checkUsername, trigger: 'blur' }]">
                      <el-input v-model="model.username" placeholder="账号名称" prefix-icon="el-icon-user"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-12">
                    <el-form-item prop="password" :rules="[
                        {required:true, message:'请填写登录密码,6位以上'},
                        { min:6, message:'登录密码至少6位长度'}
                      ]">
                      <el-input type="password" v-model="model.password" placeholder="登录密码" prefix-icon="el-icon-key"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="mt-n3 mb-3 text-muted font-italic" v-show="showPwdStrength">
                  <small>密码强度: <span class="font-weight-700" :class="pwdStrengthColor">{{ pwdStrength }}</span></small>
                </div>
                
                <el-divider>企业信息</el-divider>
                <div class="row">
                  <div class="col-12">
                    <el-form-item prop="company" :rules="[{required:true, message:'请填写公司名称'}]">
                      <el-input v-model="model.company" placeholder="公司名称"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <el-form-item prop="orgType" :rules="[{required:true, message:'请选择企业性质'}]">
                      <el-select v-model="model.orgType" placeholder="选择企业性质">
                        <el-option v-for="item in orgs" :key="item.name" :label="item.name" :value="item.name">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row" v-if="model.orgType === city_org">
                  <div class="col-12">
                    <el-form-item prop="orgName" :rules="[{required:true, message:'请选择国企单位'}]">
                      <el-select v-model="model.orgName" placeholder="选择国企单位">
                        <el-option v-for="item in orgNames" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row" v-else-if="model.orgType === ditrict_org">
                  <div class="col-12">
                    <el-form-item prop="orgDistrict">
                      <el-select v-model="model.orgDistrict" placeholder="选择地区">
                        <el-option v-for="item in orgDistricts" :key="item" :label="item" :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div class="row" v-if="model.orgType !== city_org">
                  <div class="col-12">
                    <el-form-item prop="orgName" :rules="[{required:true, message:'请填写所属国企单位'}]">
                      <el-input v-model="model.orgName" placeholder="所属国企单位" required></el-input>
                      <small class="text-muted"><em>* 请填所属国企的政府部门登记核准名称的全称</em></small>
                    </el-form-item>
                  </div>
                </div>
                
                <el-divider>联系方式</el-divider>
                <div class="row">
                  <div class="col-5 pr-1">
                    <el-form-item prop="contactName">
                      <el-input v-model="model.contactName" placeholder="联系人"></el-input>
                    </el-form-item>
                  </div>
                  <div class="col-7 pl-1">
                    <el-form-item prop="contactPhone">
                      <el-input type="number" v-model="model.contactPhone" placeholder="联系电话"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <el-form-item prop="contactEmail">
                      <el-input v-model="model.contactEmail" placeholder="联系邮箱地址" required></el-input>
                    </el-form-item>
                  </div>
                </div>

                <!-- <div class="form-group">
                  <div class="input-group input-group-alternative">
                    <div class="input-group-prepend" title="账号类型">
                      <span class="input-group-text">
                        <i class="ni ni-pin-3"></i>
                      </span>
                    </div>
                    <div class="form-control d-flex">
                      <base-radio name="counter" v-model="model.type">专柜</base-radio>
                      <base-radio name="store" class="ml-3" v-model="model.type">专馆</base-radio>
                      <base-radio name="region" class="ml-3" v-model="model.type">专区</base-radio>
                    </div>
                  </div>
                </div> -->
                
                <div class="text-center">
                  <button type="button" class="btn btn-primary my-4 ls-3 btn-block" @click="submit">注册</button>
                </div>
                <div class="text-center">
                  <router-link to="/login">登录</router-link>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <modal :show.sync="modal" @close="leave">
          <h6 slot="header" class="modal-title" id="modal-title-default">提交成功</h6>
          <p>还差一步...</p>
          <p>统计中心工作人员将在1个工作日内对注册信息进行审核。请等待管理员审核并激活账号。</p>
          <template slot="footer">
              <base-button type="primary" @click="leave">知道了</base-button>
          </template>
      </modal>
    </div>
  </div>
</template>
<script>
  /* eslint-disable */
  //import { Divider } from 'element-ui';
  const PATTERN_PWD_STRENGTH_H = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=([\x21-\x7e]+)[^a-zA-Z0-9]).{8,18}/;
  const PATTERN_PWD_STRENGTH_M = /(?=.*[0-9])(?=.*[a-zA-Z])(?=([\x21-\x7e]+)[^a-zA-Z0-9]).{8,18}/;
  const PATTERN_PWD_STRENGTH_L = /[0-9a-zA-z_]{8,18}/;

  export default {
    // components: {
    //   [Divider.name]: Divider
    // },
    name: 'register',
    data() {
      return {
        loading: false,
        modal: false,
        model: {
          username: '',
          password: '',
          company: '',
          contactName: '',
          contactPhone: '',
          contactEmail: ''
        },
        pwdStrength: '',
        pwdStrengthColor: '',
        city_org: '市属国有企业',
        ditrict_org: '区属国有企业',
        orgs: []
      }
    },
    computed: {
      showPwdStrength() {
        return this.pwdStrength != ''
      },
      orgNames() {
        const cityOrg = this.orgs.find(x => x.name === this.city_org)
        if (cityOrg) return cityOrg.companies
      },
      orgDistricts() {
        const districtOrg = this.orgs.find(x => x.name === this.ditrict_org)
        if (districtOrg) return districtOrg.districts
      }
    },
    watch: {
      'model.password'(v) {
        if(PATTERN_PWD_STRENGTH_H.test(v)) {
          this.pwdStrength = '强', this.pwdStrengthColor = 'text-success'
        } else if(PATTERN_PWD_STRENGTH_M.test(v)) {
          this.pwdStrength = '中等', this.pwdStrengthColor = 'text-warning'
        } else if(PATTERN_PWD_STRENGTH_L.test(v)) {
          this.pwdStrength = '弱', this.pwdStrengthColor = 'text-danger'
        } else {
          this.pwdStrength = ''
        }
      }
    },
    methods: {
      checkUsername(rule, value, callback) {
        if (!value) {
          return callback(new Error('请输入账号名称'));
        }
        if(!/^[a-zA-Z0-9_]{4,16}$/.test(value)) {
          return callback(new Error('账号名称至少4位长度，不能包含特殊字符'));
        }
        this.axios.get('users/exists', { params: {username: value} }).then((res) => {
          if (res.data.errcode == 0) {
            if(res.data.usernameExists) {
              callback(new Error('账号名称已被使用，请输入其他名称'));
            }
            else {
              callback()
            }
          }
          else {
            callback()
          }
        })
      },
      submit() {
        this.$refs['regform'].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.axios.get('users/create', {params: this.model}).then((res) => {
              if (res.data.errcode == 0) {
                this.modal = true;
              }
              this.loading = false;
            }).catch((err) => {
                if(err.response) {
                  if(err.response.data.error) this.$notify({type:'warning', message: err.response.data.error.message});
                  this.$notify({type:'warning', message: err.response.data[0].message});
                }
                else this.$notify({type:'warning', message: 'Failed: ' + err.message});
                this.loading = false;
            })
          } else {
            console.log('form invalid')
            return false;
          }
        });
      },
      leave() {
        this.$router.push('/login')
      }
    },
    mounted() {
      this.axios.get('orglib').then((res) => {
        this.orgs = res.data
      })
    }
  };
</script>
<style scoped>
  .el-divider__text {
    background-color: #f7fafc;
    color: #8898aa;
    font-weight: 300;
    letter-spacing: 0.125rem;
  }
  .el-input, .el-select, .el-autocomplete {
        width: 100%;
    }
</style>
