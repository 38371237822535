<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">账号列表</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div>
        <card refs="l1" class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
          <div class="row mb-3">
            <div class="col d-md-flex">
              <div class="mr-md-1 my-1 d-flex">
                <el-input class="search" v-model="filters.search" placeholder="输入关键字检索" prefix-icon="el-icon-search" clearable></el-input>
              </div>
              <div class="mx-md-1 my-1">
                <el-select v-model="filters.status" clearable placeholder="账号状态" class="fw">
                  <el-option v-for="item in statusOptions" :key="'status_'+item.value" :label="item.text" :value="item.value">
                </el-option>
              </el-select>
              </div>
              <div class="mx-md-1 my-1">
                <el-button type="success" @click="fetchData" :loading="loading" class="fw">{{ this.loading ? "加载中..." : "检索" }}</el-button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <el-table refs="usersTable" row-key="id" header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
                :data="tableData" stripe>
                <el-table-column type="expand">
                  <template slot-scope="{row}">
                      <div class="pl-5">
                        <div>
                          <h6>企业性质</h6>
                          <span>{{ row.org_type }}</span>
                        </div>
                        <div v-if="row.org_district" class="mt-3">
                          <h6>所属地区</h6>
                          <span>{{ row.org_district }}</span>
                        </div>
                        <div class="mt-3">
                          <h6>国企单位</h6>
                          <span>{{ row.org_name }}</span>
                        </div>
                        <div v-if="row.remark" class="mt-3">
                          <h6>审核意见</h6>
                          <span>{{ row.remark }}</span>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column prop="username" label="账号名称" width="140" class-name="pl-2"></el-table-column>
                <el-table-column prop="company" label="公司名称" min-width="200"></el-table-column>
                <el-table-column prop="created_at" label="创建时间" min-width="160">
                  <template slot-scope="{row}">
                    {{ formatDateTime(row.created_at) }}
                  </template>
                </el-table-column>
                <el-table-column prop="status" sortable="true" label="账号状态" width="120px">
                    <template slot-scope="scope">
                        <span v-html="statusName(scope.row.status)"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" sortable="true" label="单位状态" width="140px">
                    <template slot-scope="scope">
                        <span v-html="orgStatusName(scope.row.org_status)"></span>
                    </template>
                </el-table-column>
                <!--
                <el-table-column prop="headuser_name" label="负责人联系方式" min-width="290">
                  <template slot-scope="{row}">
                      {{row.headuser_name}}, {{row.headuser_phone}}, {{row.headuser_mail}}
                  </template>
                </el-table-column>
                <el-table-column prop="headuser_name" label="主要联系人" min-width="290">
                  <template slot-scope="{row}">
                      {{row.mainuser_name}}, {{row.mainuser_phone}}, {{row.mainuser_mail}}
                  </template>
                </el-table-column>
                <el-table-column prop="created_at" sortable="true" label="注册时间" width="180">
                    <template slot-scope="scope">
                        {{ formatDateTime(scope.row.created_at) }}
                    </template>
                </el-table-column>
                -->
                <el-table-column width="160px" align="right" label="操作" class-name="pr-2">
                  <template slot-scope="{$index, row}" class="d-flex">
                    <el-button size="mini" @click="update($index, row, 1)" 
                      v-if="row.status!==1" :loading="row.updating"> {{ row.updating ? "激活中..." : "激活" }}</el-button>
                    <el-button size="mini" @click="update($index, row, 2)"
                      v-if="row.status===1" :loading="row.updating"> {{ row.updating ? "关闭中..." : "禁用" }}</el-button>
                    <el-button size="mini" @click="audit(row)"
                      :disabled="row.org_status!==0">审核</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 px-0 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-pagination class="pb-3 mx--3" background layout="sizes, prev, pager, next"
              :page-sizes="pageSizeOptions" :page-size="filters.limit"
              :total="total" :current-page="filters.page"
              @size-change="pageSizeChange" @current-change="pageIndexChange" @prev-click="movePrev" @next-click="moveNext">
            </el-pagination>
          </div>
        </card>
      </div>
    </div>
    <modal :show.sync="auditFormVisible" @close="modelClosed" :can-close="!saving" modal-classes="modal-dialog-centered" body-classes="pt-0">
      <div slot="header">
        <span class="text-success">账号审核</span>
      </div>
      <audit-form v-if="!!updatingRow" :item-id="updatingRow.org_id" @cancelled="closeModal" @saved="savedStatus" :saving.sync="saving"></audit-form>
    </modal>
  </div>
</template>
<script>
/* eslint-disable */
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
//import moment from "moment";
import swal from 'sweetalert2';

export default {
  components: {
    RouteBreadCrumb,
    'audit-form': () => import('./Audit')
  },
  data() {
    return {
      loading: false,
      filters: { search: '', status:'', limit: 10, page: 1 },
      tableData: [],
      pageSizeOptions: [10, 20, 50, 100],
      total: 0,
      statusOptions: [{ text: '未激活', value: 0 }, { text: '正常', value: 1 }, { text: '已关闭', value: 2 }],
      orgStatusOptions: [{ text: '审核中', value: 0 }, { text: '审核通过', value: 1 }, { text: '审核不通过', value: 2 }],
      auditFormVisible: false,
      updatingRow: null,
      saving: false,
      saved: false
    }
  },
  methods: {
    formatDateTime(datestr) {
      if(!datestr) return "";
      return moment(datestr).format("YYYY-MM-DD HH:mm:SS")
    },
    statusName(status) {
        let code = this.statusOptions.find((item)=>{ return item.value == status })
        if(code) {
            if(status !== 1) return `<span class="text-danger">${code.text}</span>`
            return code.text
        }
        return status
    },
    orgStatusName(status) {
        let code = this.orgStatusOptions.find((item)=>{ return item.value == status })
        if(code) {
            if(status === 2) return `<span class="text-danger">${code.text}</span>`
            return code.text
        }
        return status
    },
    fetchData() {
      this.loading = true;
      this.axios.get("users", { params: { ...this.filters } })
      .then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data.data.map(item => { item.updating=false; return item; });
            this.total = res.data.data.total;
            this.filters.page = res.data.data.page;
            this.loading = false;
        } else if (res.data.errcode == 60403) {
            setTimeout(this.fetchData, 1000)
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    pageSizeChange(v) { this.filters.limit = v; this.fetchData() },
    pageIndexChange(v) { this.filters.page = v; this.fetchData() },
    movePrev() { this.filters.page --; this.fetchData() },
    moveNext() { this.filters.page ++; this.fetchData() },
    update(i,r,status) {
        var actionName = status === 1 ? "激活" : "关闭";
        swal.fire({
            title: `确定要进行${actionName}操作?`,
            text: '您将会更改登陆账号的状态.',
            icon: 'warning',
            showCancelButton: true, focusCancel: true,
            confirmButtonText: '确定', confirmButtonColor: '#2dce89', cancelButtonText: '取消'
        }).then((res)=>{
            if(res.value){
                r.updating = true;
                this.axios.put("users/" + r.id, { status: status })
                .then((res) => {
                    if (res.data.errcode == 0) {
                        this.$notifyOK('操作成功', `账号${r.username}已成功${actionName}.`)
                        r.status = status
                    }
                    else {
                        this.$notifyErr('操作失败', res.data.errmsg);
                    }
                    r.updating = false;
                })
                .catch((e) => {
                    this.$notifyErr('操作失败', e.message);
                    r.updating = false;
                })
            }
        });
    },
    audit(row) {
        this.updatingRow = row
        this.auditFormVisible = true
        this.saved = false
    },
    savedStatus () {
      this.saved = true
      this.auditFormVisible = false
      this.modelClosed()
    },
    closeModal () {
      this.auditFormVisible = false
      this.modelClosed()
    },
    modelClosed () {
      if (this.saved) this.fetchData()
      this.updatingRow = null
    }
  },
  created() {
    setTimeout(this.fetchData, 500)
  },
};
</script>
