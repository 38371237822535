<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">企业列表</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div>
        <card refs="l1" class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="d-flex justify-content-center justify-content-sm-between flex-wrap filters">
                <div class="mb-1">
                  <el-input class="search" v-model="filters.search" placeholder="输入关键字检索" prefix-icon="el-icon-search" clearable></el-input>
                </div>
                <div class="ml-2">
                  <el-button type="success" @click="fetchData" :loading="loading">{{ this.loading ? "加载中..." : "检索" }}</el-button>
                </div>
              </div>
              <div class="mb-3 mb-md-1">
                 <el-button type="success" @click="showForm = true" plain :disabled="showForm">+ 添加企业</el-button>
              </div>
            </div>
            <div class="col-12 border-top border-bottom p-3 mb-3" style="background-color: #f0f0f0" v-if="showForm">
                <store-form :list="tableData" :saved.sync="formSaved" :cancelled.sync="formCancelled"></store-form>
            </div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-table refs="storeTable" row-key="id" header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
                :data="tableData" stripe>
                <el-table-column prop="district" label="所在区域" class-name="pl-2" min-width="100"></el-table-column>
                <el-table-column prop="store_name" label="企业名称" min-width="180"></el-table-column>
                <el-table-column prop="company_name" label="经营主体" min-width="120" v-if="false"></el-table-column>
                <el-table-column prop="gov_ent" label="专馆性质" width="160" v-if="false"></el-table-column>
                <el-table-column prop="online_offline" label="专馆形态" width="100" v-if="false"></el-table-column>
                <el-table-column prop="website" label="企业地址" min-width="200"></el-table-column>
                <el-table-column prop="cashier_producer" label="收银系统厂家" min-width="180" v-if="false"></el-table-column>
                <el-table-column prop="bank_account" label="经营主体开户银行" min-width="180"></el-table-column>
                <el-table-column prop="payments" label="专柜支付方式" min-width="160"></el-table-column>
                <el-table-column prop="api_cap" label="数据对接能力" width="130" v-if="false"></el-table-column>
                <el-table-column prop="api_en" label="数据连接状态" width="140" v-if="false"></el-table-column>
                <el-table-column prop="remark" label="备注" min-width="100"></el-table-column>
                <el-table-column width="130px" label="操作" class-name="px-2 text-center" label-class-name="action-col-header" fixed="right"
                    v-if="!isSuper || (hasUSI || hasUR)">
                    <template slot-scope="{row}">
                        <a href="javascript:;" class="border-bottom mx-1" @click="edit(row)" :disabled="isSuper && !hasUSI"><i class="el-icon-edit-outline"></i> 编辑</a>
                        <a href="javascript:;" class="border-bottom mx-1" @click="report(row)" :disabled="!isSuper || !hasUR"><i class="el-icon-date"></i> 报表</a>
                    </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div slot="footer" class="col-12 px-0 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-pagination class="pb-3 mx--3" background layout="sizes, prev, pager, next"
              :page-sizes="pageSizeOptions" :page-size="filters.limit"
              :total="total" :current-page="filters.page"
              @size-change="pageSizeChange" @current-change="pageIndexChange" @prev-click="movePrev" @next-click="moveNext">
            </el-pagination>
          </div>
        </card>
      </div>
        <modal :show.sync="editformVisible" size="lg" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
            <card type="secondary" shadow header-classes="bg-white pb-2" body-classes="p-lg-3 pt-lg-4" class="border-0">
                <store-form key="roweditform" :modeldata.sync="editingItem" :list="tableData" :saved.sync="editingItemSaved" :cancelled.sync="editFormCancelled"></store-form>
            </card>
        </modal>
        <modal :show.sync="reportVisible" size="lg" body-classes="p-0" header-classes="px-3 pb-0 text-center" modal-classes="modal-dialog-centered modal-sm">
            <div slot="header" class="modal-title px-3">
              <span class="text-success border-bottom border-success ls-1"><i class="el-icon-s-shop"></i> {{ reportStoreTitle }}</span>
            </div>
            <card type="secondary" shadow header-classes="bg-white pb-2" body-classes="p-lg-3" class="border-0">
                <store-report key="storeReport" :editable="!!isSuper" :storeid="reportStoreId" v-if="reportVisible"></store-report>
            </card>
        </modal>
    </div>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb,
    'store-form': () => import('./Widgets/StoreForm'),
    'store-report': () => import('./Widgets/StoreReport')
  },
  data() {
    return {
      loading: false,
      filters: { search: '', limit: 10, page: 1 },
      tableData: [],
      pageSizeOptions: [10, 20, 50, 100],
      total: 0,

      showForm: false,
      formSaved: false,
      formCancelled: false,

      editformVisible: false,
      editFormCancelled: false,
      editingItem: null,
      editingItemSaved: false,

      reportVisible: false,
      reportStoreId: null,
      reportStoreTitle: ''
    }
  },
  computed: {
    isSuper() {
      return this.$store.state.user.user && this.$store.state.user.user.is_super
    }
  },
  watch: {
      formSaved(v) {
          if(v) {
              this.fetchData()
              this.showForm = false
          }
      },
      formCancelled(v) {
          if(v) this.showForm = false
      },
      editFormCancelled(v) {
          if(v) this.editformVisible = false
      },
      editingItemSaved(v) {
          if(v) {
              this.fetchData()
              this.editformVisible = false
          }
      }
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.axios.get("stores", { params: { ...this.filters } })
      .then((res) => {
        if (res.data.errcode == 0) {
            this.tableData = res.data.data.data.map(item => { item.updating=false; return item; });
            this.total = res.data.data.total;
            this.filters.page = res.data.data.page;
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    pageSizeChange(v) { this.filters.limit = v; this.fetchData() },
    pageIndexChange(v) { this.filters.page = v; this.fetchData() },
    movePrev() { this.filters.page --; this.fetchData() },
    moveNext() { this.filters.page ++; this.fetchData() },
    edit(row) {
        this.editingItem = row
        this.editformVisible = true
    },
    report(row) {
        this.reportStoreId = row.id
        this.reportStoreTitle = row.store_name
        this.reportVisible = true
    }
  },
  created() {
    setTimeout(this.fetchData, 500)
  }
};
</script>
<style>
.no-border-card .card-footer { border-top: 0; }
.filters>div { margin-bottom:15px; }
.filters .search.el-input { width: 230px; }
.filters .el-select { width: 160px }
.cell.action-col-header {
    display: block !important;
    text-align: center;
    padding-top: 9px !important;
}
</style>
