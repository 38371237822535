<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col">
          <h6 class="h2 text-white d-inline-block mb-0">「送菜上门」服务名单</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div>
        <card refs="l1" class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
            <div class="row mb-3">
                <div class="col d-md-flex">
                  <div class="d-md-flex flex-grow-1">
                    <div class="mr-md-1 my-1">
                        <el-date-picker v-model="filters.month" type="month" class="mr-1 fw" placeholder="月份"
                            format="yyyy-MM" value-format="yyyy-MM">
                        </el-date-picker>
                    </div>
                    <div class="mr-md-1 my-1">
                    <el-input class="search" v-model="filters.search" placeholder="输入关键字检索" prefix-icon="el-icon-search" clearable></el-input>
                    </div>
                    <div class="mx-md-1 my-1">
                      <el-button type="success" @click="fetchData(false)" :loading="loading" class="fw">{{ this.loading ? "加载中..." : "检索" }}</el-button>
                    </div>
                    <div class="mx-md-1 my-1">
                      <el-button type="info" @click="exportData" :loading="exporting" class="fw">导出</el-button>
                    </div>
                  </div>
                  <div>
                    <div class="my-1 text-center">
                        <el-button type="success" @click="showForm = true" plain :disabled="showForm || !!isSuper" v-if="hasESC">+ 添加名单</el-button>
                        <el-button type="success" @click="showImport = true" plain :disabled="showImport || !!isSuper" v-if="hasESC"><i class="el-icon-upload"></i> 导入</el-button>
                    </div>
                  </div>
                </div>
            </div>
            <div class="row">
                <div class="col bborder-top border-bottom p-3 mb-3" style="background-color: #f0f0f0" v-if="showForm">
                    <edit-form :list="tableData" :saved.sync="formSaved" :group="group" :cancelled.sync="formCancelled"></edit-form>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <el-table refs="storeTable" row-key="id" header-row-class-name="thead-light" style="width: 100%" v-loading="loading"
                        :data="tableData" stripe>
                        <el-table-column prop="index" label="序号" class-name="pl-2" width="60"></el-table-column>
                        <el-table-column prop="team" label="工作队" min-width="100"></el-table-column>
                        <el-table-column prop="company" label="原单位" min-width="200"></el-table-column>
                        <el-table-column prop="name" label="干部姓名" min-width="120"></el-table-column>
                        <el-table-column prop="phone" label="干部电话" width="160"></el-table-column>
                        <el-table-column prop="relation_ship" label="关系" width="100"></el-table-column>
                        <el-table-column prop="receiver_name" label="收件人" min-width="120"></el-table-column>
                        <el-table-column prop="receiver_phone" label="收件电话" min-width="160"></el-table-column>
                        <el-table-column prop="receiver_address" label="收件地址" min-width="280"></el-table-column>
                        <el-table-column prop="deliver_time" label="配送要求" min-width="100"></el-table-column>
                        <el-table-column prop="remark" label="备注" min-width="100"></el-table-column>
                        <el-table-column width="130px" label="操作" class-name="px-2 text-center" label-class-name="action-col-header" fixed="right"
                            v-if="hasESC">
                            <template slot-scope="{row}">
                                <a href="javascript:;" class="border-bottom mx-1" @click="edit(row)"><i class="el-icon-edit-outline"></i> 编辑</a>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div slot="footer" class="col-12 px-0 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <el-pagination class="pb-3 mx--3" background layout="sizes, prev, pager, next"
                :page-sizes="pageSizeOptions" :page-size="filters.limit"
                :total="total" :current-page="filters.page"
                @size-change="pageSizeChange" @current-change="pageIndexChange" @prev-click="movePrev" @next-click="moveNext">
                </el-pagination>
            </div>
        </card>
      </div>
        <modal :show.sync="editformVisible" size="lg" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
            <card type="secondary" shadow header-classes="bg-white pb-2" body-classes="p-lg-3 pt-lg-4" class="border-0">
                <edit-form key="roweditform" :modeldata.sync="editingItem" :group="group" :list="tableData" :saved.sync="editingItemSaved" :cancelled.sync="editFormCancelled"></edit-form>
            </card>
        </modal>
        <modal :show.sync="showImport" size="lg" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
            <card type="secondary" shadow header-classes="bg-white pb-2" body-classes="p-lg-3 pt-lg-4" class="border-0">
                <import key="import-songcai" v-if="showImport" :group="group" :saved.sync="importSaved" :month.sync="importMonth" :cancelled.sync="importCancelled"></import>
            </card>
        </modal>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb,
    'edit-form': () => import('./Widgets/EditForm'),
    'import': () => import('./Widgets/Import')
  },
  data() {
    return {
      loading: false,
      exporting: false,
      group: this.$route.params.group,
      filters: { search: '', month: moment().format('YYYY-MM'), group: this.$route.params.group, limit: 20, page: 1 },
      tableData: [],
      pageSizeOptions: [20, 50, 100],
      total: 0,

      showForm: false,
      formSaved: false,
      formCancelled: false,

      editformVisible: false,
      editFormCancelled: false,
      editingItem: null,
      editingItemSaved: false,

      showImport: false,
      importSaved: false,
      importCancelled: false,
      importMonth: ''
    }
  },
  computed: {
    isSuper() {
      return this.$store.state.user.user && this.$store.state.user.user.is_super
    }
  },
  watch: {
      $route(to, from) {
        if(to.name === from.name) {
          this.group = this.$route.params.group
          this.filters.group = this.$route.params.group
          this.fetchData()
        }
      },
      formSaved(v) {
          if(v) {
              this.fetchData()
              this.showForm = false
          }
      },
      formCancelled(v) {
          if(v) this.showForm = false
      },
      editFormCancelled(v) {
          if(v) this.editformVisible = false
      },
      editingItemSaved(v) {
          if(v) {
              this.fetchData()
              this.editformVisible = false
          }
      },
      importSaved(v) {
        if(v) {
              this.filters.month = this.importMonth
              this.fetchData()
              this.showImport = false
          }
      },
      importCancelled(v) {
          if(v) this.showImport = false
      },
  },
  methods: {
    fetchData(isExport) {
      if(!isExport) this.loading = true;
      else this.exporting = true;
      this.axios.get("songcais", { params: { ...this.filters, type: isExport?'xlsx':'' }, responseType: isExport?'blob':'application/json' })
      .then((res) => {
        if(!isExport) {
          if (res.data.errcode == 0) {
              var index = (this.filters.page-1) * this.filters.limit + 1;
              this.tableData = res.data.data.data.map(item => { 
                item.index = index++;
                item.updating = false; 
                return item; 
              });
              this.total = res.data.data.total;
              this.filters.page = res.data.data.page;
          }
          else {
              this.$notifyErr('未能成功加载数据', res.data.errmsg);
          }
          this.loading = false;
        }
        else {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${this.group}.${this.filters.month}.${new Date().valueOf()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.exporting = false;
        }
      })
      .catch((e) => {
        console.error(e);
        this.loading = false;
      })
    },
    pageSizeChange(v) { this.filters.limit = v; this.fetchData() },
    pageIndexChange(v) { this.filters.page = v; this.fetchData() },
    movePrev() { this.filters.page --; this.fetchData() },
    moveNext() { this.filters.page ++; this.fetchData() },
    edit(row) {
        this.editingItem = row
        this.editformVisible = true
    },
    exportData() {
      this.fetchData(true)
    }
  },
  created() {
    setTimeout(this.fetchData, 500)
  }
};
</script>
<style>
.no-border-card .card-footer { border-top: 0; }
.filters>div { margin-bottom:15px; }
.filters .search.el-input { width: 230px; }
.filters .el-select { width: 160px }
.cell.action-col-header {
    display: block !important;
    text-align: center;
    padding-top: 9px !important;
}
.filters .fw, .fw.el-input, .fw.el-select, .el-button.fw {
  width: 100% !important;
}
</style>
